export default function NftValidationUtilityProgressBar({currentStep}) {

    const computePercentage = () => {
        switch (currentStep) {
            case 0: return 0;
            case 1: return 5;
            case 2: return 15;
            case 3: return 30;
            case 4: return 3/6*100;
            case 5: return 4/6*100;
            case 6: return 6/6*100;
            default: return 100
        }
    }
    return (
        <div>
            <h4 className="sr-only">Status</h4>
            <div className="" aria-hidden="true">
                <div className="overflow-hidden rounded-full bg-gray-200">
                    <div className="h-2 rounded-full bg-whal3s-600 transition transition-all duration-500" style={{ width: `${computePercentage()}%` }} />
                </div>
                <div className="mt-2.5 hidden grid-cols-5 text-sm font-medium text-gray-600 sm:grid">
                    <div className={`text-left ${currentStep >= 2 ? 'text-whal3s-600' :''}`}>Connect wallet</div>
                    <div className={`text-center ${currentStep >= 3 ? 'text-whal3s-600' :''}`}>Select NFT</div>
                    <div className={`text-center ${currentStep >= 4 ? 'text-whal3s-600' :''}`}>Reserve</div>
                    <div className={`text-center ${currentStep >= 5 ? 'text-whal3s-600' :''}`}>Enter data</div>
                    <div className={`text-right ${currentStep >= 6 ? 'text-whal3s-600' :''}`}>Claimed</div>
                </div>
            </div>
        </div>
    )
}
