import React, {useEffect, useState} from 'react';
import Whal3s, {NftValidationUtility} from "@whal3s/whal3s.js";
import Whal3sProductLogo from "../../assets/images/whal3s-logo-black-with-border.png";
import NftValidationUtilityProgressBar from "../ProgressBars/NftValidationUtilityProgressBar";
import Uninitialized from "./StickerClaim/0_Uninitialized";
import ConnectWallet from "./StickerClaim/1_ConnectWallet";
import LoadingNfts from "./StickerClaim/2_LoadingNfts";
import SelectNft from "./StickerClaim/3_SelectNft";
import ClaimNft from "./StickerClaim/4_ClaimNft";
import ClaimedNft from "./StickerClaim/5_ClaimedNft";


const StickerClaim = () => {
    const id = 'ca550f21-f70a-48fe-820b-e3b993bfd151';

    const [utility, setUtility] = useState(undefined);
    const [step, setStep] = useState(0);


    useEffect(() => {

        // Inititalize Whal3s and set custom wallet configuration
        const whal3s = new Whal3s();



        // Create new validation utility instance and assign to state
        whal3s.createValidationUtility(id).then((tmpUtility) => {
            tmpUtility.addEventListener('stepChanged', (step) => {
                setStep(step.detail.step)
                console.log({
                    step: step.detail.step,
                    nfts: tmpUtility.nfts.nfts
                })
                setUtility(tmpUtility)
            })
            setUtility(tmpUtility)
            setStep(tmpUtility.step)
        })


    }, [])



    return (<div
        className="border border-zinc-300 p-5 md:p-10  grid gap-5 md:gap-10 grid grid-cols-1 md:grid-cols-[1fr_2fr]">
        <div className="flex flex-col align-center">
            <h4 className="font-bold text-2xl">Whal3s Sticker - NFT holder exclusive.</h4>
            <img alt="Hoodie" src={Whal3sProductLogo}/>
        </div>
        <div className="border border-zinc-300 p-5 flex flex-col">
            <div className="flex justify-between text-sm font-medium text-gray-900 mb-2.5">
                <span>Claiming utility...</span>
                <span>Claims: {utility?.details?.engagements_count ?? 0}/{utility?.details?.max_engagements ?? '--'}</span>
            </div>
            <NftValidationUtilityProgressBar currentStep={step ?? 1}></NftValidationUtilityProgressBar>

            <hr className="my-2.5"/>

            <div className="flex-grow">
                {step === NftValidationUtility.STEP_UNINITIALIZED && <Uninitialized/>}
                {step === NftValidationUtility.STEP_INITIALIZED && <ConnectWallet utility={utility}/>}
                {step === NftValidationUtility.STEP_WALLET_CONNECTED && <LoadingNfts/>}
                {(step === NftValidationUtility.STEP_NFTS_FETCHED || step === NftValidationUtility.STEP_TOKEN_SELECTED) &&
                    <SelectNft utility={utility}/>}
                {step === NftValidationUtility.STEP_RESERVED && <ClaimNft utility={utility}/>}
                {step === NftValidationUtility.STEP_CLAIMED && <ClaimedNft utility={utility}/>}

            </div>


        </div>
    </div>);
};

export default StickerClaim;
