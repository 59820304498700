(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@web3-onboard/core"), require("@web3-onboard/injected-wallets"), require("@web3-onboard/walletconnect"));
	else if(typeof define === 'function' && define.amd)
		define(["@web3-onboard/core", "@web3-onboard/injected-wallets", "@web3-onboard/walletconnect"], factory);
	else if(typeof exports === 'object')
		exports["Whal3s"] = factory(require("@web3-onboard/core"), require("@web3-onboard/injected-wallets"), require("@web3-onboard/walletconnect"));
	else
		root["Whal3s"] = factory(root["@web3-onboard/core"], root["@web3-onboard/injected-wallets"], root["@web3-onboard/walletconnect"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__738__, __WEBPACK_EXTERNAL_MODULE__724__, __WEBPACK_EXTERNAL_MODULE__298__) => {
return 