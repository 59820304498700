import './App.css';
import Welcome from "./Pages/Welcome";

function App() {
  return (
    <div className="App">
    <Welcome></Welcome>
    </div>
  );

}

export default App;
