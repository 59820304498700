/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')

  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        },
      },
    },
  }
  ```
*/
import React from 'react'
import {ChainId, ThirdwebProvider} from "@thirdweb-dev/react";
import ClaimButton from "./ClaimButton";
import notify from "../utils/notify";
import {IoAlertOutline} from "react-icons/io5";

const steps = [
    {
        id: 1,
        step: 1,
        title: "Get testnet tokens",
         text:
            'Get some testnet tokens first to cover the gas fees',
        children:   <a
            target="_blank"
            rel="noreferrer"
            href="https://faucet.polygon.technology/"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-whal3s-600 px-5 py-3 text-base font-medium text-white hover:bg-whal3s-700"
        >
            Testnet tokens
        </a>
    },
    {
        id: 2,
        step: 2,
        title: "Claim NFT",
        text:
            'After you acquired some testnet tokens, you can claim your free Whal3s Demo NFT. To do so, you have to connect your wallet first and mint the nft afterwards.',
        children:  <ThirdwebProvider desiredChainId={ChainId.Mumbai}>
            <ClaimButton

                onError={(err) => notify('An error occured', err.reason, IoAlertOutline)}
                onSuccess={() => notify('Success', 'Whal3s NFT successfully claimed')}></ClaimButton>

        </ThirdwebProvider>
    },
    {
        id: 3,
        step: 3,
        title: "Use Utility",
        text:
            'Now that you match all prerequisites, you are ready to use the utility.',
        children:  <button type="button" onClick={() => {document.getElementById('utility').scrollIntoView({ behavior: 'smooth' })}} className="text-whal3s-800 text-base font-medium ">Click here</button>
    },
]

export default function ClaimWhal3sNft({className}) {
    return (
        <div className={`flow-root ${className}`}>
            <ul className="-mb-8">
                {steps.map((step, stepIndex) => (
                    <li key={step.id}>
                        <div className="relative pb-8">
                            {stepIndex !== steps.length - 1 ? (
                                <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                            ) : null}
                            <div className="relative flex items-start gap-x-3	">

                                        <div className="relative">
                                            <span className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white text-whal3s-800">
                                                {step.step}
                                            </span>
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <div>
                                                <div className="text-sm">
                                                    <span  className="text-base text-gray-900  sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                                        {step.title}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mt-2 text-base font-medium text-gray-500">
                                                <p>{step.text}</p>
                                            </div>
                                            <div className="mt-3 flex justify-center lg:justify-start ">
                                                {step.children}
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}
